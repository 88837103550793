<template>
  <div class="receivables-report">
    <v-card class="mb-2">
      <v-card-title> Laporan Piutang </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-select
          v-model="filter.show"
          :items="filter.showList"
          class="mb-2"
          label="Tampilkan"
          outlined
          hide-details
        ></v-select>

        <salesman-autocomplete
          class="mb-2"
          :value.sync="salesman"
          useAll
        ></salesman-autocomplete>

        <date-range-picker v-model="dateRange"></date-range-picker>
      </v-card-text>
    </v-card>

    <v-card class="mb-2" color="#15263b">
      <v-card-title class="pb-1"> Sisa Piutang </v-card-title>

      <v-card-text>
        <h2 v-text="summaries.remaining"></h2>
      </v-card-text>
    </v-card>

    <v-card class="mb-2">
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr class="header">
                <td>Tagihan Awal</td>
                <td v-text="summaries.total" class="text-right"></td>
              </tr>
              <tr>
                <td>Potongan Retur</td>
                <td v-text="summaries.returns" class="text-right"></td>
              </tr>
              <tr class="header">
                <td>Total Tagihan</td>
                <td v-text="summaries.afterReturns" class="text-right"></td>
              </tr>
              <tr>
                <td>Sudah Lunas</td>
                <td v-text="summaries.paymentDone" class="text-right"></td>
              </tr>
              <tr>
                <td>Dibayar Sebagian</td>
                <td v-text="summaries.paymentNotDone" class="text-right"></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-card class="mb-2">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        filled
        hide-details
        @input="fetchData"
      ></v-text-field>

      <v-data-table
        :headers="headers"
        :item-class="itemClass"
        :items="receivables"
        :loading="loading"
        :options.sync="options"
        :server-items-length="count"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            color="light-blue"
            small
            @click="openDetails(item.branch_code)"
          >
            Details
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import DateRangePicker from '@/components/Pickers/DateRangePicker.vue'
import SalesmanAutocomplete from '@/components/SalesmanAutocomplete.vue'

export default {
  components: {
    DateRangePicker,
    SalesmanAutocomplete,
  },

  data() {
    return {
      dateRange: null,

      filter: {
        showList: [
          { text: 'Semua', value: 0 },
          { text: 'Belum Lunas', value: 1 },
          { text: 'Sudah Lunas', value: 2 },
        ],
        show: 0,
      },

      search: '',

      options: {},

      headers: [
        { text: 'Customer', value: 'br_name' },
        { text: 'Total', value: 'total' },
        { text: 'Potongan Retur', value: 'return_alloc' },
        { text: 'Sudah Lunas', value: 'payment_done_alloc' },
        { text: 'Dibayar Sebagian', value: 'payment_not_done_alloc' },
        { text: 'Sisa Piutang', value: 'remaining' },
        { text: '', sortable: false, value: 'actions' },
      ],

      salesman: '',

      receivables: [],

      count: 0,

      allocated: 0,

      returnAllocated: 0,

      paymentDoneAllocated: 0,

      paymentNotDoneAllocated: 0,

      total: 0,

      totalAfterReturns: 0,

      remaining: 0,

      loading: false,

      summaries: {
        loading: false,
        total: 0,
        returns: 0,
        afterReturns: 0,
        paymentDone: 0,
        paymentNotDone: 0,
        remaining: 0,
      },
    }
  },

  watch: {
    options: {
      deep: true,

      handler() {
        this.fetchData()
      },
    },

    dateRange() {
      this.fetchData()
    },
  },

  mounted() {
    this.fetchSummaries()
  },

  methods: {
    fetchData() {
      if (!this.dateRange) return

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.receivables = []
      this.loading = true

      this.$http
        .get('/customers/receivables', {
          params: {
            search: this.search,
            sortBy: sortBy[0] ? sortBy[0] : '',
            sortDesc: sortDesc[0] ? sortDesc[0] : '',
            page: page,
            salesman: this.salesman,
            itemsPerPage: itemsPerPage,
            show: this.filter.show,
            startDate: this.dateRange ? this.date2sql(this.dateRange[0]) : '',
            endDate: this.dateRange ? this.date2sql(this.dateRange[1]) : '',
          },
        })
        .then((result) => {
          this.receivables = result.data.result
          this.count = result.data.count

          this.allocated = result.data.allocated
          this.returnAllocated = result.data.return_allocated
          this.paymentDoneAllocated = result.data.payment_done_allocated
          this.paymentNotDoneAllocated = result.data.payment_not_done_allocated

          this.total = result.data.total
          this.totalAfterReturns = result.data.total_after_returns
          this.remaining = result.data.remaining
          this.loading = false
        })
    },

    fetchSummaries() {
      this.summaries.loading = true

      this.$http
        .get('/customers/receivables/summaries', {
          params: {
            salesman: this.salesman,
            startDate: this.dateRange ? this.date2sql(this.dateRange[0]) : '',
            endDate: this.dateRange ? this.date2sql(this.dateRange[1]) : '',
          },
        })
        .then(({ data }) => {
          this.summaries.total = data.total
          this.summaries.returns = data.returns
          this.summaries.afterReturns = data.after_returns
          this.summaries.paymentDone = data.payments_done
          this.summaries.paymentNotDone = data.payments_not_done
          this.summaries.remaining = data.remaining
        })
    },

    refilter() {
      this.fetchSummaries()
      this.fetchData()
    },

    itemClass(item) {
      return this.removeCommas(item.remaining) > 0 ? 'bg-red' : ''
    },

    openDetails(branchCode) {
      this.$router.push(
        '/sales/receivables/' +
          branchCode +
          '/' +
          this.date2sql(this.dateRange[0]) +
          '/' +
          this.date2sql(this.dateRange[1])
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.header td {
  background-color: #333333;
}

::v-deep .bg-red td:first-child {
  background-color: #f44336 !important;
}
</style>
