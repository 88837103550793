<template>
  <div class="receivables">
    <receivables-report></receivables-report>
  </div>
</template>

<script>
import ReceivablesReport from '@/components/Reports/Sales/ReceivablesReport.vue'

export default {
  name: 'Receivables',
  components: { ReceivablesReport },
}
</script>
