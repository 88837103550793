<template>
  <v-autocomplete
    v-model="salesman"
    :items="salesmans"
    :loading="loading"
    :search-input.sync="search"
    item-text="name"
    item-value="code"
    label="Salesman"
    hide-details
    hide-no-data
    outlined
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'SalesmanAutocomplete',

  props: {
    value: String,
    useAll: Boolean,
  },

  data() {
    let salesmans = [];

    if (this.useAll) {
      salesmans[0] = { code: "0", name: "Semua" };
    }

    return {
      salesmans: salesmans,
      loading: false,
      search: null,
    };
  },

  computed: {
    salesman: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:value", val);
      },
    },
  },

  watch: {
    search(val) {
      this.loading = true;

      this.$http
        .get("/salesmans", {
          params: {
            search: val,
          },
        })
        .then(({ data }) => {
          if (this.useAll) {
            this.salesmans = [{ code: "0", name: "Semua" }];
          }
          this.salesmans = this.salesmans.concat(data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>